import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './router/permission'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Vue2Editor from 'vue2-editor'
// 引入大屏边框组件dataV
import dataV from '@jiaminghi/data-view'
// ************************************自定义**********************************
// 全局组件
import McUpload from '@/components/McUpload.vue'
import McEditor from '@/components/McEditor.vue'
import McMapScreenshot from '@/components/McMapScreenshot.vue'

Vue.component('McUpload', McUpload)
Vue.component('McEditor', McEditor)
Vue.component('McMapScreenshot', McMapScreenshot)

Vue.use(Vue2Editor)
Vue.use(ElementUI)

Vue.use(dataV)

// 控制浏览器的缩放
window.onload = function () {
  document.body.style.zoom = '100%'
}

export const MainVue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
