<template>
  <vue-editor useCustomImageHandler @image-added="handleImageAdded" v-model="value"></vue-editor>
</template>
<script>

import { UploadFile } from '@/request/Common'

export default {
  name: 'McEditor',
  props: {
    content: {
      type: String
    },
    field: {
      type: String
    }
  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    // 此处监听variable变量，当期有变化时执行
    value (item1, item2) {
      this.$emit('change', this.field, item1)
    }
  },
  created () {
    this.value = ''
    this.value = this.content
  },
  methods: {
    handleImageAdded: async function (file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData()
      formData.append('file', file)
      const result = await UploadFile(formData)
      if (result.code === 200) {
        Editor.insertEmbed(cursorLocation, 'image', result.data.url)
        resetUploader()
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
