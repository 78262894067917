import router from '@/router/index'

// 导航守卫
router.beforeEach((to, from, next) => {
  document.body.style.zoom = '100%'
  // 获取登录token
  const token = sessionStorage.getItem('token')
  // 没有登录统一跳转登录页
  // 判断当token不存在且要跳转的页面不是login页面时，跳转到login页面
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  }
  if (token && to.name === 'login') {
    // 如果token存在，且当前页面要跳转到login页面，就跳转到首页
    next({ name: 'SystemHome' })
  } else {
    next()
  }
})
