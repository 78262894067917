import html2canvas from 'html2canvas'

/**
 * 截图
 * @param ev 画布DOM
 * @returns {Promise<unknown>}
 */
export function screenshot (ev) {
  return new Promise((resolve, reject) => {
    html2canvas(ev, {
      scale: 2,
      logging: false,
      useCORS: true
    }).then(function (canvas) {
      const formData = new FormData()
      const dataUrl = canvas.toDataURL('images/jpg')
      const blob = dataURItoBlob(dataUrl)
      formData.append('file', blob, Date.now() + '.jpg')
      resolve(formData)
    }).catch(() => {
      resolve(null)
    })
  })
}

function dataURItoBlob (dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}
