export function successTip (ev, message) {
  ev.$message.success(message)
}

export function errorTip (ev, message) {
  ev.$message.error(message)
  throw new Error('停止运行')
}

export function confirmTip (ev, title) {
  return new Promise((resolve, reject) => {
    ev.$confirm(title, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      resolve(true)
    }).catch(() => {
      resolve(false)
    })
  })
}
