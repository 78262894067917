export default {
  state: {
    isCollapse: false, // 控制菜单展开或者收起
    tabList: [
      {
        path: '/',
        name: '首页',
        icon: 's-home'
      }
    ]
  },
  mutations: {
    // 修改菜单展开收起
    collapseMenu (state) {
      state.isCollapse = !state.isCollapse
    },

    // 更新面包屑数据
    selectMenu (state, val) {
      // 判断添加的数据是否是首页
      if (val.name !== '首页') {
        const index = state.tabList.findIndex(item => item.name === val.name)
        // 没有这个路由数据，就添加进去
        if (index === -1) {
          state.tabList.push(val)
        }
      }
    },

    // 删除面包屑
    closeTag (state, item) {
      const index = state.tabList.findIndex(val => val.name === item.name)
      state.tabList.splice(index, 1)
    }
  }
}
